import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { autobind } from "core-decorators";

export class PasswordComponentView extends UiComponent {
    public passwordInput: HTMLInputElement;
    public validationWrapper: HTMLElement;
    public validator: kendo.ui.Validator;
    public requirementElements: Map<HTMLElement, RegExp>;
    private readonly SEL_PASSWORD_INPUT: string = "input.js-new-password";
    private readonly SEL_PASSWORD_VALIDATION_WRAPPER: string = ".js-password-validation-wrapper";
    private readonly SEL_VALIDATOR_SPAN_IDS: string[] = [
        "#js-password-lower-case-letter-requirement",
        "#js-password-upper-case-letter-requirement",
        "#js-password-length-requirement",
        "#js-password-digit-requirement",
        "#js-password-special-character-requirement",
    ];
    private readonly DATA_REGEX_KEY = "regex";

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
        this.requirementElements = new Map<HTMLElement, RegExp>();
    }

    protected init(): void {
        this.initializeViewElements();

        this.SEL_VALIDATOR_SPAN_IDS.forEach((spanId: string) => {
            const element = this.findElement(spanId) as HTMLElement;
            if (element !== null) {
                const regex = element.dataset[this.DATA_REGEX_KEY];
                if (!regex) {
                    return;
                }
                this.requirementElements.set(element, new RegExp(regex));
            }
        });
    }

    @autobind
    public rebind(): void {
        this.initializeViewElements();
    }

    @autobind
    private initializeViewElements(): void {
        this.passwordInput = this.findElement(this.SEL_PASSWORD_INPUT) as HTMLInputElement;
        if (this.passwordInput.form) {
            this.validator = $(this.passwordInput.form).kendoValidator().data("kendoValidator");
        }
        this.validationWrapper = this.findElement(
            this.SEL_PASSWORD_VALIDATION_WRAPPER
        ) as HTMLElement;
    }
}
