import { Container } from "typescript-ioc";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import "../Styles/password.css";
import { PasswordComponent } from "Password/Scripts/PasswordComponent";
import { PasswordComponentFactory } from "Password/Scripts/PasswordComponentFactory";

declare global {
    interface IOri {
        /**
         * Password component for legacy JS access
         */
        passwordComponent?: PasswordComponent;
        passwordComponentFactory?: PasswordComponentFactory;
    }
}

ori.passwordComponentFactory = Container.get(PasswordComponentFactory);
Container.get(ReadyHelper).bindInitCodeBlocksReady(() => {
    if (document.querySelector(PasswordComponent.COMPONENT_SELECTOR)) {
        ori.passwordComponent = Container.get(UiComponentFactory).createBase(
            PasswordComponent,
            PasswordComponent.COMPONENT_SELECTOR
        );
    }
});
