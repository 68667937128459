import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { PasswordComponentView } from "Password/Scripts/PasswordComponentView";
import { autobind } from "core-decorators";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";

export class PasswordComponent extends UiComponent {
    private readonly REQUIREMENT_UNKNOWN: string = "requirement-unknown-state";
    private readonly REQUIREMENT_FULFILLED: string = "requirement-fulfilled";
    private readonly REQUIREMENT_UNFULFILLED: string = "requirement-unfulfilled";
    public static readonly COMPONENT_SELECTOR: string = "#js-password-input-model";
    private _view: PasswordComponentView;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    protected init(): void {
        this._view = this.createView(PasswordComponentView);
        this.bindValidation();
        this.bindShowRequirements();
    }

    @autobind
    private validatePasswordRequirements(event: Event): void {
        this._view.requirementElements.forEach((value, key) => {
            const isFulfilled = value.test((event.target as HTMLInputElement).value);
            key.classList.remove(this.REQUIREMENT_UNKNOWN);
            if (isFulfilled) {
                key.classList.remove(this.REQUIREMENT_UNFULFILLED);
                key.classList.add(this.REQUIREMENT_FULFILLED);
            } else {
                key.classList.remove(this.REQUIREMENT_FULFILLED);
                key.classList.add(this.REQUIREMENT_UNFULFILLED);
            }
        });
        if (this._view.validator) {
            this._view.validator.validateInput(this._view.passwordInput);
        }
    }

    @autobind
    public reBind(): void {
        this._view = this._componentFactory.create(
            $(PasswordComponent.COMPONENT_SELECTOR) as unknown as Element,
            PasswordComponentView
        );
        this.bindValidation();
        this.bindShowRequirements();
    }

    @autobind
    public bindValidation(): void {
        this._view.passwordInput.addEventListener("blur", this.validatePasswordRequirements);
        this._view.passwordInput.addEventListener("keyup", this.validatePasswordRequirements);
    }

    @autobind
    public bindShowRequirements(): void {
        this._view.passwordInput.addEventListener("focus", () => {
            this._view.validationWrapper.classList.remove("hidden");
        });
    }
}
